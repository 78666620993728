import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './static/css/global.css'
import { createRouter, createWebHistory } from 'vue-router'
import { createApp } from 'vue'
import App from './App.vue'
import { routes } from './routes'

const app = createApp(App)

const router = createRouter({
    routes,
    // history:createWebHashHistory(),
    history: createWebHistory(),
    scrollBehavior(to, from, savePosition) {
        if (savePosition) {
            return savePosition
        } else {
            return { left: 0, top: 0 }
        }
    }
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})

app.use(router)
app.mount("#app")