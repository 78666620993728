/**
 * CURRENT DATE IN NEW YORK
 * FORMAT = "MM/DD/YYYY, hh:mm:ss EDT
 **/

const localTime = "07/31/2022, 15:45:18 EDT"

const year = new Date().toLocaleString("en-US", {
    timeZone: "America/New_York", year: "numeric"
})

export default { localTime, year }