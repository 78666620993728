console.info('routes.js')

// import bio from 'views/about/bio.vue'
// import experience from 'views/about/experience.vue'
// import projects from 'views/about/projects.vue'
// import home from 'views/home.vue'
// // import calculator from 'views/misc/calculator.vue'
// // import ticTacToe from 'views/misc/tictactoe.vue'
// import notFoundComponent from 'views/notFoundComponent.vue'
// import underConstruction from 'views/underConstruction.vue'

const titles = {
    home: 'Osarodion Irabor',
    about: 'Osarodion - About',
    experience: 'Osarodion - Experience',
    projects: 'Osarodion - Projects',
    notFound: '404 - NOT FOUND',
    wip: 'Under Construction',
    calculator: 'Osarodion - Calculator',
    ticTacToe: 'Osarodion - Tic-Tac-Toe'
}

const Home = () =>
    import ('@/views/HomePage.vue')
const NotFoundPage = () =>
    import ('@/views/NotFoundPage.vue')
const CalculatorPage = () =>
    import ('@/views/misc/CalculatorPage.vue')
const TicTacToePage = () =>
    import ('@/views/misc/TicTacToePage.vue')

export const routes = [

    {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
            title: titles.home
        }
    },
    {
        path: '/',
        name: 'landing',
        component: Home,
        meta: {
            title: titles.home
        }
    },
    {
        path: '/misc/calculator',
        name: 'calculator',
        component: CalculatorPage,
        meta: {
            title: titles.calculator
        }
    },
    {
        path: '/misc/tic-tac-toe',
        name: 'tic-tac-toe',
        component: TicTacToePage,
        meta: {
            title: titles.ticTacToe
        }
    },

    {
        path: '/:pathMatch(.*)*',
        component: NotFoundPage,
        meta: {
            title: titles.notFound
        }
    }

]