<template>
  <!-- Beginning of App -->
  <div id="app">
    <!-- Header -->
    <nav class="navbar navbar-expand-md navbar-dark bg-dark sticky-top">
      <div class="container">
        <router-link
          to="/"
          tag="a"
          class="navbar-brand nav-text-items"
        >Osarodion Irabor</router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto mr-auto">
            <li class="nav-item">
              <router-link
                v-if="hoverable"
                to="/home"
                tag="a"
                class="hover-elem nav-link"
              >
                Home
                <span class="sr-only">(current)</span>
              </router-link>
              <router-link v-else to="/home" tag="a" class="nav-link">
                Home
                <span class="sr-only">(current)</span>
              </router-link>
            </li>
            <li
              v-if="hoverable"
              class="misc-dropdown nav-item dropdown"
              @mouseover="show('misc')"
              @mouseleave="hide('misc')"
            >
              <a
                class="hover-elem nav-link dropdown-toggle"
                href="#"
                role="button"
                data-toggle="dropdown"
                id="misc-navbarDropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >Misc</a>
              <div
                class="misc-dropdown dropdown-menu"
                aria-labelledby="misc-navbarDropdown"
                @mouseover="show('misc')"
                @mouseleave="hide('misc')"
                @click="hide('misc')"
              >
                <router-link
                  to="/misc/calculator"
                  tag="a"
                  class="dropdown-item-v"
                >Calculator</router-link>
                <div class="dropdown-divider"></div>
                <router-link
                  to="/misc/tic-tac-toe"
                  tag="a"
                  class="dropdown-item-v"
                >Tic-Tac-Toe</router-link>
              </div>
            </li>
            <li v-else class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-toggle="dropdown"
                id="misc-navbarDropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >Misc</a>
              <div class="dropdown-menu" aria-labelledby="misc-navbarDropdown">
                <router-link
                  to="/misc/calculator"
                  tag="a"
                  class="dropdown-item-v"
                >Calculator</router-link>
                <div class="dropdown-divider"></div>
                <router-link
                  to="/misc/tic-tac-toe"
                  tag="a"
                  class="dropdown-item-v"
                >Tic-Tac-Toe</router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- End of Header -->

    <!-- Body -->
    <div class="container-fluid" id="body_content">
      <router-view></router-view>
    </div>

    <!-- End of Body -->

    <!-- Footer -->

    <footer class="footer" id="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 copyright">
            <p class="footer_text">&#169; 2019-{{year}} Osarodion Irabor</p>
          </div>
          <div class="col-md-4">
            <p class="footer_text backend_log">Last Updated: {{nyTimeStamp}}</p>
          </div>
          <div class="col-sm">
            <ul class="social_profiles">
              <li class="svg_icons">
                <a
                  href="https://github.com/lightning-dabbler"
                  class="social-button social-button-github"
                  target="_blank"
                  name="GitHub"
                ></a>
              </li>
              <li class="svg_icons">
                <a
                  href="https://www.linkedin.com/in/osarodion%F0%9F%94%A5-i-173195b6/"
                  class="social-button social-button-linkedin"
                  target="_blank"
                  name="LinkedIn"
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>

    <!-- End Footer -->
  </div>
  <!-- End of App -->
</template>

<script>
console.info("module: app.vue");
import timestamp from "./lib/timestamp";
import { hoverable} from "./lib/utils.js";
function show(dropdown) {
  document.querySelector(
    `li[class^="${dropdown}-dropdown nav-item dropdown"]`
  ).className = `${dropdown}-dropdown nav-item dropdown show`;
  document.querySelector(
    `div[class^="${dropdown}-dropdown dropdown-menu"]`
  ).className = `${dropdown}-dropdown dropdown-menu show`;
  document
    .getElementById(`${dropdown}-navbarDropdown`)
    .setAttribute("aria-expanded", true);
}

function hide(dropdown) {
  document.querySelector(
    `li[class^="${dropdown}-dropdown nav-item dropdown"]`
  ).className = `${dropdown}-dropdown nav-item dropdown`;
  document.querySelector(
    `div[class^="${dropdown}-dropdown dropdown-menu"]`
  ).className = `${dropdown}-dropdown dropdown-menu`;
  document
    .getElementById(`${dropdown}-navbarDropdown`)
    .setAttribute("aria-expanded", false);
}

export default {
  name: "app",
  data() {
    return {
      nyTimeStamp: timestamp.localTime,
      greeting: "Hello",
      year: timestamp.year,
      hoverable: !hoverable()
    };
  },
  methods: {
    hide,
    show
  }
};
</script>

<style lang="scss" scoped>
$text-color: #ebe7e7;
$footer-bg-color: #ac4141;

/*
NAVBAR STYLES
*/

.navbar-toggler:focus {
  outline: none;
}
.bg-dark {
  background-color: #000 !important;
}

.nav-link {
  color: $text-color !important;
  outline: none;
}
.hover-elem:hover {
  color: $footer-bg-color !important;
}

.dropdown-menu {
  background-color: $footer-bg-color;
  margin: -0.125rem 0 0;
  padding: 0;
  border-radius: 0;
}

.dropdown-item-v:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item-v:focus,
.dropdown-item-v:hover {
  background-clip: padding-box;
}

.dropdown-item-v {
  display: block;
  padding: 0.75rem 1.5rem;
  width: 100%;
  clear: both;
  color: #212529;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
}

.dropdown-divider {
  border-top: 1px solid #000;
  margin: 0;
}

@media all and (min-width: 1200px) {
  .mr-auto {
    margin-right: 8rem !important;
  }
}
@media all and (max-width: 1199px) {
  .mr-auto {
    margin-right: 6.5rem !important;
  }
}
@media all and (max-width: 1039px) {
  .mr-auto {
    margin-right: 8rem !important;
  }
}

@media all and (max-width: 991px) {
  .mr-auto {
    margin-right: 4rem !important;
  }
}

@media all and (max-width: 844px) {
  .mr-auto {
    margin-right: 5.5rem !important;
  }
}

@media all and (max-width: 789px) {
  .mr-auto {
    margin-right: 6rem !important;
  }
}
@media all and (max-width: 767px) {
  .mr-auto {
    margin-right: auto !important;
  }
}

@media all and (max-width: 767.98px) {
  a.nav-link {
    max-width: 75px;
  }
}
///////////// NAVBAR STYLE END /////////////

/*
BODY STYLES
*/

#body_content {
  min-height: 81vh;
  padding-left: 0;
  padding-right: 0;
  height: 100%;
}

@media all and (min-height: 850px) {
  #body_content {
    min-height: 90vh;
  }
}

///////////// BODY STYLE END ///////////////

/*
FOOTER STYLES
*/
.footer > div.container {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.footer div.col-lg-4,
.footer div.col-md-4 {
  padding-top: 15px;
  padding-bottom: 15px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

a.social-button {
  display: block;
  width: 40px;
  height: 40px;
}

a.social-button.social-button-github {
  background: url("@/static/images/mark-github.svg");
  background-size: cover;
}

a.social-button.social-button-linkedin {
  background: url("@/static/images/square-black-linkedin.svg");
  background-size: cover;
}
.social_profiles {
  float: right;
  list-style: none;
  margin: 10px 0 10px 0;
  padding: 0;
}
.social_profiles .svg_icons {
  float: left;
  margin: 0.2rem;
}
#footer {
  background-color: $footer-bg-color;
  bottom: 0;
}

.footer_text {
  text-align: center;
  font-size: 1.1rem;

  color: $text-color;

  -webkit-text-fill-color: $text-color; /* Will override color (regardless of order) */

  -webkit-text-stroke-width: 0.1px;

  -webkit-text-stroke-color: black;
  margin-bottom: 0;
}

.backend_log {
  font-size: 0.6rem;
}

@media all and (max-width: 991px) {
  .copyright {
    background-color: #b23e3e;
  }
}
///////////// FOOTER STYLE END /////////////
</style>
